*{
    box-sizing: border-box;
    margin:0;
}

.flex-around{
    display: flex;
    justify-content: space-around;
}
.flex-between{
    display: flex;
    justify-content: space-between;
    
}
.restaurant{
    display:grid;
    grid-template-columns: 150px auto;
    grid-gap: 1cm;
    padding: 1cm;
    background-color: rgba(255, 255, 255, 0.08);
    width:100%;
}

.resto{
    padding:20px;
    font-size: 15px; 
    /* font-weight: 500;  */
    background-color: rgba(0,128,128, 0.4);
}

h1 h4{
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;  
}

.Home{
    background-image: url(/static/media/outdoor-img.c1222524.jpg);
    height:100vh;
    width:100vw;
    background-repeat: no-repeat;
    background-size: cover;
    display:flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    font-family: sans-serif;
}
.Home .banner{
    background-color: rgba(223, 97, 75, 0.3);
    padding: 0.5cm;
    border-radius: 0.5cm;
    text-align: center;
    color:whitesmoke;
    display:flex;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: .3cm;
}
.Home div button{
    display:block;
    margin:auto;
}

.Home a:hover{
    color:lightsalmon;
}

h1{
    font-size: 1.2cm;
    font-family: 'Times New Roman', Times, serif;
    
}

.Restaurants{
    background-image: url(/static/media/rest01.1184112a.jpg); 
    height:100%;
    width:100vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: white;
}

a {
    color: whitesmoke;
    text-decoration: none;
}

.buttonfinder{
    width:100%;
    padding:0.2cm;
    text-align: center;
    border-radius: 1cm;
    background-color: #00a680;
}

.About{
    background-image: url(/static/media/rest0-unsplash.5cc3a48f.jpg);
    min-height: 10;
    height:100%;
    width:100vw;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: white;
    
    
}
.About .banner{
    width:100%;
    margin: auto;
    min-height: 100vh;
    display: flex;
    align-items: end;
    justify-content: center;
    
}


